<template lang="pug">
  v-app
    router-link.brand-logo(to="/")
      img(
        src="@/assets/images/icon/logo.png"
      )
    router-view#main-page
</template>

<script>
export default {
  mounted() {
    this.$i18n.locale = this.$store.state.customer.locale;
  }
};
</script>

<style lang="sass" scoped>
// .v-application
//   height: 100vh !important
//   overflow: hidden !important

.brand-logo
  position: fixed
  left: 4vw
  top: 4vw
  z-index: 99
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: 30%

#main-page
  margin-top: 5rem
</style>
